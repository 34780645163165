/* eslint-disable camelcase */
import { baseApi as api } from './baseApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFile: build.mutation<CreateFileApiResponse, CreateFileApiArg>({
      query: (queryArg) => ({
        url: `/cm_fss/api/v1/files`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-Correlation-Id': queryArg['X-Correlation-Id'] }
      }),
      invalidatesTags: () => [{ type: 'Files', id: 'LIST' }]
    }),
    getFiles: build.query<GetFilesApiResponse, GetFilesApiArg>({
      query: (queryArg) => ({
        url: `/cm_fss/api/v1/files`,
        headers: { 'X-Correlation-Id': queryArg['X-Correlation-Id'] },
        params: {
          operator_code: queryArg.operatorCode,
          app_type: queryArg.appType,
          file_name: queryArg.fileName,
          sort: queryArg.sort,
          offset: queryArg.offset,
          limit: queryArg.limit
        }
      }),
      providesTags: (result) =>
        result?.files
          ? [
              // eslint-disable-next-line camelcase
              ...result.files.map(({ id }) => ({ type: 'Files' as const, id: id })),
              { type: 'Files', id: 'LIST' }
            ]
          : [{ type: 'Files', id: 'LIST' }]
    }),
    deleteFile: build.mutation<DeleteFileApiResponse, DeleteFileApiArg>({
      query: (queryArg) => ({
        url: `/cm_fss/api/v1/files/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'X-Correlation-Id': queryArg['X-Correlation-Id'] }
      }),
      invalidatesTags: () => [{ type: 'Files', id: 'LIST' }]
    }),
    patchFile: build.mutation<PatchFileApiResponse, PatchFileApiArg>({
      query: (queryArg) => ({
        url: `/cm_fss/api/v1/files/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.patchFileRequest,
        headers: { 'X-Correlation-Id': queryArg['X-Correlation-Id'] }
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Files', id: id }]
    }),
    getFile: build.query<GetFileApiResponse, GetFileApiArg>({
      query: (queryArg) => ({
        url: `/cm_fss/api/v1/files/${queryArg.id}`,
        headers: { 'X-Correlation-Id': queryArg['X-Correlation-Id'] }
      }),
      providesTags: (result, error, { id }) => [{ type: 'Files', id: id }]
    }),
    downloadFile: build.query<DownloadFileApiResponse, DownloadFileApiArg>({
      query: (queryArg) => ({
        url: `/cm_fss/api/v1/files/download`,
        headers: { 'X-Correlation-Id': queryArg['X-Correlation-Id'] },
        params: {
          operator_code: queryArg.operatorCode,
          app_type: queryArg.appType,
          file_name: queryArg.fileName,
          file_extension: queryArg.fileExtension
        },
        responseHandler: (res) => res.blob()
      })
    }),
    downloadFiles: build.query<DownloadFilesApiResponse, DownloadFilesApiArg>({
      query: (queryArg) => ({
        url: `/cm_fss/api/v1/files/download/tar`,
        headers: { 'X-Correlation-Id': queryArg['X-Correlation-Id'] },
        params: {
          operator_code: queryArg.operatorCode,
          app_type: queryArg.appType,
          file_names_prefix: queryArg.fileNamesPrefix,
          file_extension: queryArg.fileExtension,
          source_code: queryArg.sourceCode
        },
        responseHandler: (res) => res.blob()
      })
    }),
    version: build.query<VersionApiResponse, VersionApiArg>({
      query: () => ({ url: `cm_fss/version` })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as cmFssApi };
export type CreateFileApiResponse = /** status 200 Файл создан */ FileInfo;
export type CreateFileApiArg = {
  /** Идентификатор запроса */
  'X-Correlation-Id'?: string;
  body: any;
};
export type GetFilesApiResponse = /** status 200 Информация о файле */ FileInfoList;
export type GetFilesApiArg = {
  /** Идентификатор запроса */
  'X-Correlation-Id'?: string;
  operatorCode: string;
  appType: string;
  fileName?: string;
  sort?: string;
  offset?: number;
  limit?: number;
};
export type DeleteFileApiResponse = /** status 204 Файл успешно удален */ undefined;
export type DeleteFileApiArg = {
  /** Идентификатор запроса */
  'X-Correlation-Id'?: string;
  id: string;
};
export type PatchFileApiResponse = /** status 200 Файл изменен */ FileInfo;
export type PatchFileApiArg = {
  /** Идентификатор запроса */
  'X-Correlation-Id'?: string;
  id: string;
  patchFileRequest: PatchFileRequest;
};
export type GetFileApiResponse = /** status 200 Файл создан */ FileInfo;
export type GetFileApiArg = {
  /** Идентификатор запроса */
  'X-Correlation-Id'?: string;
  id: string;
};
export type DownloadFileApiResponse = unknown;
export type DownloadFileApiArg = {
  /** Идентификатор запроса */
  'X-Correlation-Id'?: string;
  operatorCode: string;
  appType: string;
  fileName: string;
  fileExtension: string;
};
export type DownloadFilesApiResponse = unknown;
export type DownloadFilesApiArg = {
  /** Идентификатор запроса */
  'X-Correlation-Id'?: string;
  operatorCode: string;
  appType: string;
  fileNamesPrefix?: string[];
  fileExtension?: string;
  sourceCode?: string;
};
export type VersionApiResponse = /** status 200 Версия */ Version;
export type VersionApiArg = void;
export type FileInfo = {
  id?: string;
  operator_code?: string;
  app_type?: string;
  file_name?: string;
  file_extension?: string;
  created?: string;
  updated?: string;
  file_size?: number;
  source_code?: string;
};
export type Error = {
  code: number;
  message: string;
  detail?: string;
};
export type RealErrArray = Error[];
export type ErrorArr = {
  errors: RealErrArray;
};
export type Pagination = {
  count: number;
  offset: number;
  limit: number;
};
export type FileInfoList = {
  files?: FileInfo[];
  pagination?: Pagination;
};
export type PatchFileRequest = {
  file_name?: string;
};
export type Version = {
  version: string;
};
export const {
  useCreateFileMutation,
  useGetFilesQuery,
  useDeleteFileMutation,
  usePatchFileMutation,
  useGetFileQuery,
  useLazyGetFilesQuery,
  useDownloadFileQuery,
  useLazyDownloadFileQuery,
  useDownloadFilesQuery,
  useVersionQuery
} = injectedRtkApi;
