const routerMessageCodesPathsConfig = {
  absolutePath: 'message-codes',
  add: {
    absolutePath: 'message-codes/add',
    relativePath: 'add'
  },
  edit: {
    absolutePath: `message-codes/edit/:symbolCode`,
    relativePath: 'edit/:symbolCode'
  }
};

export default routerMessageCodesPathsConfig;
