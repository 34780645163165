const styledComponentsTheme = {
  colors: {
    primary: {
      primaryGreen: '#57B035',
      primaryBlack: '#373A36',
      primaryWhite: '#FFFFFF'
    },
    secondary: {
      yellow: '#FFC700',
      redError: '#EB5757',
      blue: '#1E3C96',
      cyan: '#5BB4F0',
      orange: '#FF8000',
      aqua: '#29A8B1',
      crayolaSeaGreen: '#37B690',
      lightGreen: '#72BF44',
      darkGreen: '#007F02',
      beige: '#F9F9F9'
    },
    grayscale: {
      background: '#FCFCFC',
      grayscale100: '#F5F5F5',
      grayscale200: '#F1F3F3',
      grayscale300: '#E9E9E9',
      grayscale400: '#D9D9D9',
      grayscale500: '#B4B4B4',
      grayscale600: '#8A8D8F',
      grayscale700: '#7A7A7A',
      grayscale800: '#4D4D4D',
      grayscale900: '#262626'
    },
    transparent: {
      yellow20: 'rgba(255, 199, 0, 20%)',
      redError20: 'rgba(240, 0, 85, 20%)',
      blue20: 'rgba(30, 60, 150, 20%)',
      cyan20: 'rgba(91, 180, 240, 20%)',
      orange20: 'rgba(255, 128, 0, 20%)',
      primaryGreen20: 'rgba(87, 176, 53, 20%)',
      primaryGreen6: 'rgba(87, 176, 53, 6%)',
      redError6: 'rgba(240, 0, 85, 6%)',
      primaryBlack80: 'rgba(55, 58, 54, 80%)'
    }
  },
  fonts: {
    roboto: 'Roboto, sans-serif',
    openSans: 'OpenSans, sans-serif'
  },
  sidebar: {
    height: '100vh',
    width: '270px',
    minWidth: '55px',
    fontSize: '14px'
  },
  topbar: {
    height: '60px',
    width: '100%'
  }
};

export default styledComponentsTheme;
