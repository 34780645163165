import React, { lazy } from 'react';
import RouterPaths from '@router/routerPaths';
import { Route } from 'react-router-dom';

const AddMessageCode = lazy(() => import('@components/Containers/SymbolCodes/AddSymbolCode/ui/AddSymbolCode'));
const EditSymbolCode = lazy(() => import('@components/Containers/SymbolCodes/EditSymbolCode/ui/EditSymbolCode'));
const MessageCodes = lazy(() => import('@components/Containers/SymbolCodes/SymbolCodes'));

const messageCodeRoutes = () => (
  <Route
    path={RouterPaths.messageCodes.absolutePath}
    handle={{
      breadcrumb: { label: 'breadcrumbs.messageCodes' },
      permission: 'get-code'
    }}>
    <Route path="" element={<MessageCodes />} />
    <Route
      path={RouterPaths.messageCodes.add.relativePath}
      element={<AddMessageCode />}
      handle={{
        breadcrumb: { label: 'breadcrumbs.addMessageCodes' },
        permission: ['post-symbol-code', 'post-numeric-code']
      }}
    />
    <Route
      path={RouterPaths.messageCodes.edit.relativePath}
      element={<EditSymbolCode />}
      handle={{
        breadcrumb: { label: (data: any) => `${data.symbolCode}` },
        permission: 'get-code'
      }}
    />
  </Route>
);

export default messageCodeRoutes;
