export const LOCALES = {
  RU: 'ru',
  EN: 'en'
} as const; // export as const prevents accidental mutation (freezes object)

export const APP_TYPES = {
  OTT: 'aptp_ott',
  SMART_HOME: 'aptp_smh',
  APP_PLATFORM: 'aptp_app',
  SECOND_SCREEN: 'aptp_scr'
} as const;

export const TEMPLATE_FORMATS = {
  STRING: 'string',
  INTEGER: 'integer',
  FLOAT: 'float',
  BOOLEAN: 'boolean',
  BASE64: 'base64',
  JSON: 'json',
  RANGE: 'range',
  GRAPHIC: 'graphic'
} as const;

export const PAGINATION_LIMIT_OPTIONS = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 }
];

/**
 * Values to be passed into dropdown format selectors
 */
export const TEMPLATE_FORMAT_OPTIONS = [
  {
    label: 'parameterTemplates.string',
    value: TEMPLATE_FORMATS.STRING
  },
  {
    label: 'parameterTemplates.boolean',
    value: TEMPLATE_FORMATS.BOOLEAN
  },
  {
    label: 'parameterTemplates.float',
    value: TEMPLATE_FORMATS.FLOAT
  },
  {
    label: 'parameterTemplates.integer',
    value: TEMPLATE_FORMATS.INTEGER
  },
  {
    label: 'parameterTemplates.base64',
    value: TEMPLATE_FORMATS.BASE64
  },
  {
    label: 'parameterTemplates.json',
    value: TEMPLATE_FORMATS.JSON
  },
  {
    label: 'parameterTemplates.range',
    value: TEMPLATE_FORMATS.RANGE
  },
  {
    label: 'parameterTemplates.graphic',
    value: TEMPLATE_FORMATS.GRAPHIC
  }
];

export const STATUSES = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable'
};

export const MAX_INT = 2_147_483_647;
