import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink, Outlet, useLocation, useMatches, useNavigate } from 'react-router-dom';
import { selectPermissions, selectUsername } from '@slices/authSlice/authSelectors';
import { setPermissions, setUsername } from '@slices/authSlice/authSlice';
import RouterPaths from '@router/routerPaths';
import { ReactComponent as GSLogo } from '@img/GSLogo.svg';
import { ReactComponent as OperatorsIcon } from '@img/operatorsIcon.svg';
import { ReactComponent as ParamTemplatesIcon } from '@img/paramTemplatesIcon.svg';
import { ReactComponent as MessageCodesIcon } from '@img/messageCodesIcon.svg';
import { ReactComponent as StbConfigsIcon } from '@img/stbConfigsIcon.svg';
import { ReactComponent as CatalogIcon } from '@img/catalogIcon.svg';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Header, Heading, LangSwitcher, Page403, SidebarNew } from '@ui-kit/ui-kit';
import useLangsWithIcons from '@/utils/shared/hooks/useLangsWithIcons';
import Spinner from '@components/Shared/Spinner';
import i18n from '@/i18n';
import useGetPermissions from '@/utils/shared/hooks/useGetPermissions';
import { hasPermission } from '@/utils/shared/hooks/useRights';
import { useAppSelector } from '@/redux/store/hooks';
import { getEnvVariable } from '@/utils/shared/sharedFunctions';

const StyledMain = styled.div`
  display: flex;
  flex-flow: column;
  height: max-content;
  min-height: 100vh;
  width: 100%;
  padding: 12px;
  background-color: ${(props) => props.theme.colors.grayscale.grayscale100};
`;

const Layout: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const matches = useMatches();

  const username = useSelector(selectUsername);
  const permissions = useAppSelector(selectPermissions);
  const langsWithIcons = useLangsWithIcons();
  const [getPermissionsTrigger, getPermissionsTriggerState] = useGetPermissions();

  const [isPermitted, setIsPermitted] = useState(true);
  const [isPermissionChecking, setIsPermissionChecking] = useState(true);

  const defaultLangIcon = langsWithIcons.find((i) => i.lng === i18n.language)?.icon;

  useEffect(() => {
    getPermissionsTrigger();
  }, []);

  useEffect(() => {
    setIsPermissionChecking(true);
    const permission = (matches.find((match) => match.pathname === pathname)?.handle as Record<string, unknown>)
      ?.permission;
    if (permission) {
      setIsPermitted(
        Array.isArray(permission)
          ? hasPermission(permissions, ...permission)
          : hasPermission(permissions, permission as string | string[])
      );
    }
    setIsPermissionChecking(false);
  }, [permissions?.length, pathname]);

  const handleLogout = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    dispatch(setUsername(undefined));
    dispatch(setPermissions([]));
    navigate(RouterPaths.login.absolutePath);
  };

  const sidebarItems = [
    ...(hasPermission(permissions, 'get-operator')
      ? [
          {
            label: t('operators.operators'),
            icon: <OperatorsIcon />,
            path: RouterPaths.operators.absolutePath
          }
        ]
      : []),
    ...(hasPermission(permissions, 'get-param-template')
      ? [
          {
            label: t('parameterTemplates.parameterTemplates'),
            icon: <ParamTemplatesIcon />,
            path: RouterPaths.parameterTemplates.absolutePath
          }
        ]
      : []),
    ...(hasPermission(permissions, 'get-code')
      ? [
          {
            label: t('symbolCodes.symbolCodes'),
            icon: <MessageCodesIcon />,
            path: RouterPaths.messageCodes.absolutePath
          }
        ]
      : []),
    ...(hasPermission(permissions, 'get-stb-config')
      ? [
          {
            label: t('stbConfigurations.stbConfigurations'),
            icon: <StbConfigsIcon />,
            path: RouterPaths.stbConfigurations.absolutePath
          }
        ]
      : []),
    ...(hasPermission(permissions, ['get-language', 'get-solution', 'get-source', 'get-devicetype', 'get-deviceclass'])
      ? [
          {
            label: t('catalogs.catalogs'),
            icon: <CatalogIcon />,
            path: RouterPaths.settings.languages.absolutePath
          }
        ]
      : [])
  ];

  return (
    <div style={{ display: 'flex', minHeight: '100vh', height: 'max-content' }}>
      <SidebarNew
        userName={username as string}
        linksList={sidebarItems}
        routerLink={NavLink}
        pathname={pathname}
        collapseButtonLabel={t('shared.collapseMenu')}
        hasHomeButton={getEnvVariable('MAINMENU_ACTIVE').toString() === 'true'}
        homeButtonLabel={t('shared.mainMenu')}
        homeButtonLink={`${getEnvVariable('MAINMENU_HOST')}?token=${localStorage.getItem('token')}`}
      />
      <StyledMain>
        <div style={{ marginBottom: '12px' }}>
          <Header
            headerLogo={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <GSLogo style={{ width: '100px', height: 'fit-content' }} />
                <Heading titleSize={'titleXSmall'} style={{ marginLeft: '8px' }} label={'DRE Config Manager'} />
              </div>
            }
            rightChildren={<LangSwitcher langsWithIcons={langsWithIcons} defaultIcon={defaultLangIcon} />}
            logOutCallback={handleLogout}
            logOutButtonLabel={t('shared.logout')}
          />
        </div>
        <Suspense fallback={<Spinner />}>
          {getPermissionsTriggerState.isFetching || isPermissionChecking ? (
            <Spinner />
          ) : getPermissionsTriggerState.error ? ( // token is required to fetch permissions. User might have rotten token when opening app, so we redirect him to login page
            <Navigate to={RouterPaths.login.absolutePath} replace state={{ path: location.pathname }} />
          ) : isPermitted ? (
            <Outlet />
          ) : (
            <Page403 />
          )}
        </Suspense>
      </StyledMain>
    </div>
  );
};

export default Layout;
