import React from 'react';
import App from '@components/App';
import { store } from './redux/store/store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import 'modern-normalize';
import { ThemeProvider } from 'styled-components';
import styledComponentsTheme from '@/utils/shared/styledComponentsTheme';
import GlobalFontsInjector from '@/utils/shared/GlobalFontsInjector';
import './i18n';

const root = createRoot(document.getElementById('root') as Element);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={styledComponentsTheme}>
      <GlobalFontsInjector />
      <App />
    </ThemeProvider>
  </Provider>
);
