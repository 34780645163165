import {
  GetPermissionsApiArgs,
  GetPermissionsApiResponse,
  LoginApiArgs,
  LoginApiResponse
} from '@/utils/types/auth/authTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { baseApi as api } from '@/api/baseApi';

export const authApi = api.injectEndpoints({
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    login: build.mutation<LoginApiResponse, LoginApiArgs>({
      query: (credentials) => ({
        url: 'api/v1/token',
        params: {
          user_id: credentials.username
        },
        method: 'POST',
        body: credentials
      })
    }),
    getPermissions: build.query<GetPermissionsApiResponse, GetPermissionsApiArgs>({
      query: (credentials) => ({
        url: 'api/v1/permissions',
        params: {
          user_id: credentials.username
        },
        keepUnusedDataFor: 0
      })
    })
  })
});

export const { useLoginMutation, useGetPermissionsQuery, useLazyGetPermissionsQuery } = authApi;
