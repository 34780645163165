import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OperatorsState {
  appTypesFilter: string[] | [];
  statusesFilter: string[] | [];
}

const initialState: OperatorsState = {
  appTypesFilter: [],
  statusesFilter: []
};

export const operatorsSlice = createSlice({
  name: 'operatorsFilters',
  initialState,
  reducers: {
    setSelectedOperatorsAppTypesFilter: (state: OperatorsState, action: PayloadAction<string[] | []>) => {
      state.appTypesFilter = action.payload;
    },
    setSelectedOperatorsStatusesFilter: (state: OperatorsState, action: PayloadAction<string[] | []>) => {
      state.statusesFilter = action.payload;
    }
  }
});

export const { setSelectedOperatorsAppTypesFilter, setSelectedOperatorsStatusesFilter } = operatorsSlice.actions;

export default operatorsSlice.reducer;
