import React from 'react';
import Router from '@router/Router';
import styled from 'styled-components/macro';
import { GSTheme, ToastsContainer, UIKitProvider } from '@ui-kit/ui-kit';

const StyledApp = styled.div`
  &::selection {
    color: ${(props) => props.theme.colors.primary.primaryWhite};
    background: ${(props) => props.theme.colors.primary.primaryGreen};
  }
`;

/**
 * Main application component
 * @constructor
 */
function App() {
  return (
    <StyledApp className="App">
      <UIKitProvider theme={GSTheme}>
        <Router />
        <ToastsContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          pauseOnHover
          theme="dark"
        />
      </UIKitProvider>
    </StyledApp>
  );
}

export default App;
