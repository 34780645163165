import { Navigate, Route } from 'react-router-dom';
import RouterPaths from '@router/routerPaths';
import React, { lazy } from 'react';
import { generateAbsoluteRouterPath } from '@/utils/shared/sharedFunctions';

const Operators = lazy(() => import('@components/Containers/Operators/Operators'));
const OperatorTabs = lazy(() => import('@components/Containers/Operators/EditOperator/OperatorTabs'));
const AddOperator = lazy(() => import('@components/Containers/Operators/AddOperator/AddOperator'));
const EditOperator = lazy(
  () =>
    import('@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsInfo/EditOperatorTabsInfo')
);
const EditOperatorTabsConfigTable = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsValues/OperatorTabsValuesTable'
    )
);
const EditOperatorTabsTitlesTable = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsTitles/OperatorTabsTitlesTable'
    )
);
const EditOperatorTabsUserMessagesTable = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsTexts/OperatorTabsTextsTable'
    )
);
const EditOperatorTabsFilesTable = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsFiles/EditOperatorTabsFilesTable'
    )
);

const EditOperatorTabsFilesEdit = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsFiles/EditOperatorTabsFilesEdit'
    )
);
const OperatorTabsConfigParamAdd = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsValues/OperatorTabsValuesAdd/OperatorTabsValuesAdd'
    )
);
const OperatorTabsConfigEdit = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsValues/OperatorTabsValuesEdit/OperatorTabsValuesEdit'
    )
);
const OperatorTabsTitlesAdd = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsTitles/OperatorTabsTitlesAdd'
    )
);
const OperatorTabsTitlesEdit = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsTitles/OperatorTabsTitlesEdit'
    )
);
const OperatorTabsUserMessagesEdit = lazy(
  () =>
    import('@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsTexts/OperatorTabsTextsEdit')
);
const OperatorTabsLicensesEdit = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsLicenses/OperatorTabsLicensesEdit/OperatorTabsLicensesEdit'
    )
);
const OperatorTabsLicensesAdd = lazy(
  () =>
    import(
      '@components/Containers/Operators/EditOperator/EditOperatorTabs/EditOperatorTabsLicenses/OperatorTabsLicensesAdd/OperatorTabsLicensesAdd'
    )
);

const operatorRoutes = () => (
  <Route>
    <Route path="" element={<Navigate to={RouterPaths.operators.absolutePath} />} />
    <Route
      path={RouterPaths.operators.absolutePath}
      handle={{
        breadcrumb: { label: 'breadcrumbs.operators' },
        permission: 'get-operator'
      }}>
      <Route path="" element={<Operators />} />
      <Route
        path={RouterPaths.operators.edit.relativePath}
        element={<OperatorTabs />}
        handle={{
          breadcrumb: {
            label: (data: any) => `${data.operatorCode} (${data.appType})`,
            path: (data: any) => generateAbsoluteRouterPath(RouterPaths.operators.edit.texts.absolutePath, { ...data })
          }
        }}>
        <Route
          path={RouterPaths.operators.edit.info.relativePath}
          element={<EditOperator />}
          handle={{ permission: 'get-operator' }}
        />
        <Route path={RouterPaths.operators.edit.licenses.relativePath} handle={{ permission: 'get-license' }}>
          <Route path="" element={<OperatorTabsLicensesEdit />} />
          <Route
            path={RouterPaths.operators.edit.licenses.add.relativePath}
            element={<OperatorTabsLicensesAdd />}
            handle={{ permission: 'post-license' }}
          />
        </Route>
        <Route path={RouterPaths.operators.edit.titles.relativePath} handle={{ permission: 'get-operator-title' }}>
          <Route path="" element={<EditOperatorTabsTitlesTable />} />
          <Route
            path={RouterPaths.operators.edit.titles.add.relativePath}
            element={<OperatorTabsTitlesAdd />}
            handle={{ permission: 'post-operator-title' }}
          />
          <Route
            path={RouterPaths.operators.edit.titles.edit.relativePath}
            element={<OperatorTabsTitlesEdit />}
            handle={{ permission: 'get-operator-title' }}
          />
        </Route>
        <Route path={RouterPaths.operators.edit.values.relativePath} handle={{ permission: 'get-configuration' }}>
          <Route path="" element={<EditOperatorTabsConfigTable />} />
          <Route
            path={RouterPaths.operators.edit.values.add.relativePath}
            element={<OperatorTabsConfigParamAdd />}
            handle={{ permission: 'post-configuration' }}
          />
          <Route
            path={RouterPaths.operators.edit.values.edit.relativePath}
            element={<OperatorTabsConfigEdit />}
            handle={{ permission: 'get-configuration' }}
          />
        </Route>
        <Route path={RouterPaths.operators.edit.texts.relativePath} handle={{ permission: 'get-text' }}>
          <Route path="" element={<EditOperatorTabsUserMessagesTable />} />
          <Route
            path={RouterPaths.operators.edit.texts.edit.relativePath}
            element={<OperatorTabsUserMessagesEdit />}
            handle={{ permission: 'get-text' }}
          />
        </Route>
        <Route path={RouterPaths.operators.edit.files.relativePath} handle={{ permission: 'get-file' }}>
          <Route path="" element={<EditOperatorTabsFilesTable />} />
          <Route
            path={RouterPaths.operators.edit.files.edit.relativePath}
            element={<EditOperatorTabsFilesEdit />}
            handle={{ permission: 'get-file' }}
          />
        </Route>
      </Route>
      <Route
        path={RouterPaths.operators.add.relativePath}
        element={<AddOperator />}
        handle={{
          breadcrumb: { label: 'breadcrumbs.addOperator' },
          permission: 'post-configuration'
        }}
      />
    </Route>
  </Route>
);

export default operatorRoutes;
