import { Action, configureStore, isRejectedWithValue, Middleware, ThunkAction } from '@reduxjs/toolkit';
import authReducer from '@slices/authSlice/authSlice';
import { baseApi } from '@/api/baseApi';
import { cmStbApi } from '@/api/cmStbApi';
import { cmUmsApi } from '@/api/cmUmsApi';
import { authApi } from '@/api/authApi';
import operatorsReducer from '@slices/operatorsSlices/operators/operatorsSlice';
import operatorValuesReducer from '@slices/operatorsSlices/operatorValues/operatorValuesSlice';
import paramTemplatesReducer from '@slices/paramTemplatesSlices/templatesSlice';
import messageCodesReducer from '@slices/messageCodesSlices/messageCodesSlice';
import { cmFssApi } from '@/api/cmFssApi';
import { useToast } from '@ui-kit/ui-kit';

const codeStopList: number[] = [509, 664];

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  const { sendErrorToast } = useToast();

  // renders toast on every failed request except for the code stop list

  if (
    isRejectedWithValue(action) &&
    // @ts-ignore
    !codeStopList.some((code: number) => code === action.payload?.data?.errors?.[0].code)
  ) {
    // @ts-ignore
    sendErrorToast(action.payload?.data?.errors?.[0].title);
    console.error(action);
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    auth: authReducer,
    operators: operatorsReducer,
    templates: paramTemplatesReducer,
    operatorValues: operatorValuesReducer,
    messageCodes: messageCodesReducer
  },
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      baseApi.middleware,
      cmStbApi.middleware,
      cmUmsApi.middleware,
      cmFssApi.middleware,
      rtkQueryErrorLogger
    )
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
