import { baseApi as api } from './baseApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    cmStbGetVersion: build.query<CmStbGetVersionApiResponse, CmStbGetVersionApiArg>({
      query: () => ({ url: `/api/v1/version` })
    }),
    cmStbCreateStbConfig: build.mutation<CmStbCreateStbConfigApiResponse, CmStbCreateStbConfigApiArg>({
      query: (queryArg) => ({
        url: `/cm_stb/api/v1/stb_configs`,
        method: 'POST',
        body: queryArg.protoCreateStbConfigReq
      }),
      invalidatesTags: () => [{ type: 'STBConfigs', id: 'LIST' }]
    }),
    cmStbGetStbConfigList: build.query<CmStbGetStbConfigListApiResponse, CmStbGetStbConfigListApiArg>({
      query: (queryArg) => ({
        url: `/cm_stb/api/v1/stb_configs/operators`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          stb_operator_codes: queryArg.stbOperatorCodes,
          stb_serial_number: queryArg.stbSerialNumber
        }
      }),
      providesTags: () => [{ type: 'STBConfigs', id: 'LIST' }]
    }),
    cmStbGetStbConfig: build.query<CmStbGetStbConfigApiResponse, CmStbGetStbConfigApiArg>({
      query: (queryArg) => ({ url: `/cm_stb/api/v1/stb_configs/${queryArg.stbSerialNumber}` })
    }),
    cmStbDeleteStbConfig: build.mutation<CmStbDeleteStbConfigApiResponse, CmStbDeleteStbConfigApiArg>({
      query: (queryArg) => ({ url: `/cm_stb/api/v1/stb_configs/${queryArg.stbSerialNumber}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: 'STBConfigs', id: 'LIST' }]
    }),
    cmStbMigrateStb: build.query<CmStbMigrateStbApiResponse, CmStbMigrateStbApiArg>({
      query: (queryArg) => ({
        url: `/cm_stb/api/v1/stb_migration_file`,
        params: { stb_serial_number: queryArg.stbSerialNumber, stb_operator_code: queryArg.stbOperatorCode }
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as cmStbApi };
export type CmStbGetVersionApiResponse = /** status 200 A successful response. */ ProtoVersionResponse;
export type CmStbGetVersionApiArg = void;
export type CmStbCreateStbConfigApiResponse = /** status 200 A successful response. */ ProtoStbConfig;
export type CmStbCreateStbConfigApiArg = {
  protoCreateStbConfigReq: ProtoCreateStbConfigReq;
};
export type CmStbGetStbConfigListApiResponse = /** status 200 A successful response. */ ProtoStbConfigList;
export type CmStbGetStbConfigListApiArg = {
  offset?: number;
  limit?: number;
  stbOperatorCodes?: string;
  stbSerialNumber?: string;
};
export type CmStbGetStbConfigApiResponse = /** status 200 A successful response. */ ProtoStbConfig;
export type CmStbGetStbConfigApiArg = {
  stbSerialNumber: string;
};
export type CmStbDeleteStbConfigApiResponse =
  /** status 200 A successful response. */
  {} | /** status 204 Stb config was deleted successfully. */ any;
export type CmStbDeleteStbConfigApiArg = {
  stbSerialNumber: string;
};
export type CmStbMigrateStbApiResponse =
  /** status 200 A successful response. */
  ProtoMigrationRes | /** status 204 No migration required. */ any;
export type CmStbMigrateStbApiArg = {
  stbSerialNumber?: string;
  stbOperatorCode?: string;
};
export type ProtoVersionResponse = {
  version?: string;
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type ProtoStbConfig = {
  stb_serial_number?: string;
  stb_operator_code?: string;
  stb_migration_file_name?: string;
};
export type ProtoCreateStbConfigReq = {
  stb_serial_number?: string;
  stb_operator_code?: string;
  stb_migration_file?: string;
};
export type ProtoPagination = {
  offset?: number;
  limit?: number;
  count?: number;
};
export type ProtoStbConfigList = {
  pagination?: ProtoPagination;
  stb_configs?: ProtoStbConfig[];
};
export type ProtoMigrationRes = {
  stb_migration_file?: string;
};
export const {
  useCmStbGetVersionQuery,
  useCmStbCreateStbConfigMutation,
  useCmStbGetStbConfigListQuery,
  useCmStbGetStbConfigQuery,
  useCmStbDeleteStbConfigMutation,
  useCmStbMigrateStbQuery
} = injectedRtkApi;
