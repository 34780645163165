import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OperatorsState {
  formatsFilter: string[] | [];
  sourcesFilter: string[] | [];
}

const initialState: OperatorsState = {
  formatsFilter: [],
  sourcesFilter: []
};

export const operatorValuesSlice = createSlice({
  name: 'operatorValuesFilters',
  initialState,
  reducers: {
    setSelectedOperatorValuesFormatsFilter: (state: OperatorsState, action: PayloadAction<string[] | []>) => {
      state.formatsFilter = action.payload;
    },
    setSelectedOperatorsValuesSourcesFilter: (state: OperatorsState, action: PayloadAction<string[] | []>) => {
      state.sourcesFilter = action.payload;
    }
  }
});

export const { setSelectedOperatorValuesFormatsFilter, setSelectedOperatorsValuesSourcesFilter } =
  operatorValuesSlice.actions;

export default operatorValuesSlice.reducer;
