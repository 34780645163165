const routerSettingsPathsConfig = {
  absolutePath: 'settings',
  languages: {
    absolutePath: 'settings/languages',
    relativePath: 'languages'
  },
  appTypes: {
    absolutePath: 'settings/appTypes',
    relativePath: 'appTypes'
  },
  deviceTypes: {
    absolutePath: 'settings/deviceTypes',
    relativePath: 'deviceTypes'
  },
  deviceClasses: {
    absolutePath: 'settings/deviceClasses',
    relativePath: 'deviceClasses'
  },
  sources: {
    absolutePath: 'settings/sources',
    relativePath: 'sources'
  }
};

export default routerSettingsPathsConfig;
