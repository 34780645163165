import React, { lazy } from 'react';
import RouterPaths from '@router/routerPaths';
import { Route } from 'react-router-dom';

const StbConfigurations = lazy(() => import('@components/Containers/StbConfigurations/StbConfigurations'));
const AddStbConfiguration = lazy(
  () => import('@components/Containers/StbConfigurations/AddStbConfiguration/AddStbConfiguration')
);

const stbConfigurationRoutes = () => {
  return (
    <Route
      path={RouterPaths.stbConfigurations.absolutePath}
      handle={{
        breadcrumb: { label: 'breadcrumbs.stbConfigurations' }
      }}>
      <Route path="" element={<StbConfigurations />} />
      <Route
        path={RouterPaths.stbConfigurations.add.relativePath}
        element={<AddStbConfiguration />}
        handle={{
          breadcrumb: { label: 'breadcrumbs.addStbConfiguration' },
          permission: 'post-stb-config'
        }}
      />
    </Route>
  );
};

export default stbConfigurationRoutes;
