const routerOperatorPathsConfig = {
  absolutePath: 'operators',
  add: {
    absolutePath: 'operators/add',
    relativePath: 'add'
  },
  edit: {
    absolutePath: 'operators/edit/:operatorCode/:appType',
    relativePath: 'edit/:operatorCode/:appType',
    info: {
      absolutePath: 'operators/edit/:operatorCode/:appType/info',
      relativePath: 'info'
    },
    licenses: {
      absolutePath: 'operators/edit/:operatorCode/:appType/licenses',
      relativePath: 'licenses',
      add: {
        absolutePath: 'operators/edit/:operatorCode/:appType/licenses/add',
        relativePath: 'add'
      }
    },
    titles: {
      absolutePath: 'operators/edit/:operatorCode/:appType/titles',
      relativePath: 'titles',
      add: {
        absolutePath: 'operators/edit/:operatorCode/:appType/titles/add',
        relativePath: 'add'
      },
      edit: {
        absolutePath: 'operators/edit/:operatorCode/:appType/titles/edit/:operatorTitleCode/:languageCode',
        relativePath: 'edit/:operatorTitleCode/:languageCode'
      }
    },
    values: {
      absolutePath: 'operators/edit/:operatorCode/:appType/values',
      relativePath: 'values',
      add: {
        absolutePath: 'operators/edit/:operatorCode/:appType/values/add',
        relativePath: 'add'
      },
      edit: {
        absolutePath: 'operators/edit/:operatorCode/:appType/values/edit/:configParamCode',
        relativePath: 'edit/:configParamCode'
      }
    },
    texts: {
      absolutePath: 'operators/edit/:operatorCode/:appType/texts',
      relativePath: 'texts',
      edit: {
        absolutePath: 'operators/edit/:operatorCode/:appType/texts/edit/:symbolCode',
        relativePath: 'edit/:symbolCode'
      }
    },
    files: {
      absolutePath: 'operators/edit/:operatorCode/:appType/files',
      relativePath: 'files',
      edit: {
        absolutePath: 'operators/edit/:operatorCode/:appType/files/edit/:fileCode',
        relativePath: 'edit/:fileCode'
      }
    }
  }
};

export default routerOperatorPathsConfig;
