import React from 'react';
import { ReactComponent as RUFlagIcon } from '@img/ruFlagIcon.svg';
import { ReactComponent as UKFlagIcon } from '@img/ukFlagIcon.svg';
import i18n from 'i18next';

// eslint-disable-next-line react/display-name
const useLangsWithIcons = (): { icon: JSX.Element; onItemClick: () => void; label: string, lng: string }[] => {
  const handleLocaleChange = (lang: string): void => {
    i18n.changeLanguage(lang);
  };

  return [
    {
      label: 'Русский',
      icon: <RUFlagIcon style={{ width: '16px', height: 'fit-content' }} />,
      onItemClick: () => handleLocaleChange('ru'),
      lng: 'ru'
    },
    {
      label: 'English',
      icon: <UKFlagIcon style={{ width: '16px', height: 'fit-content' }} />,
      onItemClick: () => handleLocaleChange('en'),
      lng: 'en'
    }
  ];
};

export default useLangsWithIcons;
