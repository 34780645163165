import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactSelectOption } from '@ui-kit/ui-kit/dist/types/types';

interface ParamTemplatesState {
  formatsFilter: ReactSelectOption<string, string>[] | null;
  sourcesFilter: ReactSelectOption<string, string>[] | null;
  solutionsFilter: ReactSelectOption<string, string>[] | null;
}

export const templatesInitialState: ParamTemplatesState = {
  formatsFilter: null,
  sourcesFilter: null,
  solutionsFilter: null
};

export const templatesSlice = createSlice({
  name: 'templateFilters',
  initialState: templatesInitialState,
  reducers: {
    setSelectedTemplateFormatsFilter: (
      state: ParamTemplatesState,
      action: PayloadAction<ReactSelectOption<string, string>[] | null>
    ) => {
      state.formatsFilter = action.payload;
    },
    setSelectedTemplateSourcesFilter: (
      state: ParamTemplatesState,
      action: PayloadAction<ReactSelectOption<string, string>[] | null>
    ) => {
      state.sourcesFilter = action.payload;
    },
    setSelectedTemplateSolutionsFilter: (
      state: ParamTemplatesState,
      action: PayloadAction<ReactSelectOption<string, string>[] | null>
    ) => {
      state.solutionsFilter = action.payload;
    },
    resetTemplateFilters: (state: ParamTemplatesState) => {
      state.formatsFilter = templatesInitialState.formatsFilter;
      state.sourcesFilter = templatesInitialState.sourcesFilter;
      state.solutionsFilter = templatesInitialState.solutionsFilter;
    }
  }
});

export const {
  setSelectedTemplateFormatsFilter,
  setSelectedTemplateSourcesFilter,
  setSelectedTemplateSolutionsFilter,
  resetTemplateFilters
} = templatesSlice.actions;

export default templatesSlice.reducer;
