import routerOperatorPathsConfig from '@components/Router/routerPathsConfig/routerOperatorPathsConfig';
import routerParameterTemplatesPathsConfig from '@components/Router/routerPathsConfig/routerParameterTemplatesPathsConfig';
import routerMessageCodesPathsConfig from '@components/Router/routerPathsConfig/routerMessageCodesPathsConfig';
import routerStbPathsConfig from '@components/Router/routerPathsConfig/routerStbPathsConfig';
import routerSettingsPathsConfig from '@components/Router/routerPathsConfig/routerSettingsPathsConfig';

/*
  Combines route paths into one config
 */
const RouterPaths = {
  login: {
    absolutePath: 'login'
  },
  operators: routerOperatorPathsConfig,
  parameterTemplates: routerParameterTemplatesPathsConfig,
  messageCodes: routerMessageCodesPathsConfig,
  stbConfigurations: routerStbPathsConfig,
  settings: routerSettingsPathsConfig
} as const;

export default RouterPaths;
