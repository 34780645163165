import { useAppSelector } from '@/redux/store/hooks';
import { selectPermissions } from '@slices/authSlice/authSelectors';

type UseRightsProps = Array<string[] | string>;

export const hasPermission = (mappedPermissions: string[], ...rightPacks: UseRightsProps) =>
  rightPacks.some((pack) =>
    Array.isArray(pack) ? pack.every((right) => mappedPermissions.includes(right)) : mappedPermissions.includes(pack)
  );

/**
 * Hook checks if user has passed rights. Accepts arbitrary amount of string or string arrays.
 * If several arguments are passed, element existence check works as logical 'OR' operator.
 * If some argument is array, presence of every element inside an array is checked, hence it works as 'AND' logical operator for every element.
 * @param rightPacks
 */
const useRights = (...rightPacks: UseRightsProps) => {
  const permissions = useAppSelector(selectPermissions);

  return hasPermission(permissions, ...rightPacks);
};

export default useRights;
