import { createGlobalStyle } from 'styled-components';
import RobotoRegularWoff from '@/assets/fonts/roboto-v29-latin_cyrillic-regular.woff';
import RobotoRegularWoff2 from '@/assets/fonts/roboto-v29-latin_cyrillic-regular.woff2';
import Roboto500Woff from '@/assets/fonts/roboto-v29-latin_cyrillic-500.woff';
import Roboto500Woff2 from '@/assets/fonts/roboto-v29-latin_cyrillic-500.woff2';
import Roboto700Woff from '@/assets/fonts/roboto-v29-latin_cyrillic-700.woff';
import Roboto700Woff2 from '@/assets/fonts/roboto-v29-latin_cyrillic-700.woff2';
import OpenSansRegularWoff from '@/assets/fonts/open-sans-v27-latin_cyrillic-regular.woff';
import OpenSansRegularWoff2 from '@/assets/fonts/open-sans-v27-latin_cyrillic-regular.woff2';
import OpenSans700Woff from '@/assets/fonts/open-sans-v27-latin_cyrillic-700.woff';
import OpenSans700Woff2 from '@/assets/fonts/open-sans-v27-latin_cyrillic-700.woff2';

const GlobalFontsInjector = createGlobalStyle`
  /* roboto-regular - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url(${RobotoRegularWoff}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url(${RobotoRegularWoff2}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* roboto-500 - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
    url(${Roboto500Woff}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url(${Roboto500Woff2}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* roboto-700 - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url(${Roboto700Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url(${Roboto700Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* open-sans-regular - latin_cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url(${OpenSansRegularWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url(${OpenSansRegularWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* open-sans-700 - latin_cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url(${OpenSans700Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url(${OpenSans700Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

`

export default GlobalFontsInjector;
