import React from 'react';
import styled from 'styled-components/macro';
import PropagateLoader from 'react-spinners/PropagateLoader';

const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-color: ${(props) => props.theme.colors.primary.primaryGreen};
`;

/**
 * Component renders rotating circle spinner.
 * @constructor
 */
const Spinner: React.FC = () => {
  return (
    <StyledSpinner>
      <PropagateLoader color={'#57B035'} />
    </StyledSpinner>
  );
};

export default Spinner;
