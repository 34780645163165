const routerParameterTemplatesPathsConfig = {
  absolutePath: 'parameter-templates',
  add: {
    absolutePath: 'parameter-templates/add',
    relativePath: 'add'
  },
  edit: {
    absolutePath: 'parameter-templates/edit/:templateCode',
    relativePath: 'edit/:templateCode'
  }
};

export default routerParameterTemplatesPathsConfig;
