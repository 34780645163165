import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactSelectOption } from '@ui-kit/ui-kit/dist/types/types';

interface MessageCodesState {
  sourcesFilter: ReactSelectOption<string, string>[] | null;
}

const messageCodesInitialState: MessageCodesState = {
  sourcesFilter: null
};

export const messageCodesSlice = createSlice({
  name: 'messageCodeFilters',
  initialState: messageCodesInitialState,
  reducers: {
    setSelectedSourcesFormatFilter: (
      state: MessageCodesState,
      action: PayloadAction<ReactSelectOption<string, string>[]>
    ) => {
      state.sourcesFilter = action.payload;
    },
    resetSelectedMessageCodesFilters: (state: MessageCodesState) => {
      state.sourcesFilter = messageCodesInitialState.sourcesFilter;
    }
  }
});

export const { setSelectedSourcesFormatFilter } = messageCodesSlice.actions;

export default messageCodesSlice.reducer;
