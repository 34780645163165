import { useLazyGetPermissionsQuery } from '@/api/authApi';
import { setPermissions } from '@slices/authSlice/authSlice';
import { useAppDispatch } from '@/redux/store/hooks';

const useGetPermissions = (): [() => void, Record<string, unknown>] => {
  const dispatch = useAppDispatch();
  const username = localStorage.getItem('username');
  const [getPermissionsTrigger, getPermissionsTriggerState] = useLazyGetPermissionsQuery();

  const fetchPermissions = () => {
    if (username) {
      getPermissionsTrigger({ username })
        .unwrap()
        .then((res) => {
          const fetchedPermissions = res?.scope?.cmaccess ?? [];
          dispatch(setPermissions(fetchedPermissions));
        });
    }
  };

  return [fetchPermissions, getPermissionsTriggerState];
};

export default useGetPermissions;
