import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '@/redux/store/hooks';
import { setUsername } from '@slices/authSlice/authSlice';
import RouterPaths from '@router/routerPaths';

/**
 * Component wraps private routes. Redirects to login page if user is not logged.
 * @constructor
 */
const PrivateRouteWrapper: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const username = localStorage.getItem('username');
    const token = searchParams.get('token');
    username && dispatch(setUsername(username));
    if (token) {
      localStorage.setItem('token', token);
      searchParams.delete('token');
      setSearchParams(searchParams);
      navigate(location.pathname, { replace: true });
    }
  }, []);

  return localStorage.getItem('token') && localStorage.getItem('username') ? (
    <Outlet />
  ) : (
    <Navigate to={RouterPaths.login.absolutePath} replace state={{ path: location.pathname }} />
  );
};

export default PrivateRouteWrapper;
